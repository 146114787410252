import store, { IStore } from '@/store';
import { RouteLocationNormalized } from 'vue-router';

type TLoader = (state: IStore, to: RouteLocationNormalized) => Promise<null>;
type TChecker = (state: IStore) => boolean;

export function combineLoaders(...args: TLoader[]) {
    return (state: IStore, to: RouteLocationNormalized): Promise<null> => {
        return Promise.all(args.map((loader) => loader(state, to))).then(() => null);
    };
}

export function combineCheckers(...args: TChecker[]) {
    return (state: IStore): boolean => {
        return args.every((isLoad) => isLoad(state));
    };
}

// #region Films
export function isFilmLoaded(state: IStore): boolean {
    return store.state.main.isAuth ? state.films.isInitiated : true;
}

export function loadFilms(state: IStore, to: RouteLocationNormalized): Promise<null> {
    if (store.state.main.isAuth) {
        return Promise.resolve(store.dispatch('films/loadIfNeed'));
    } else {
        return Promise.resolve(null);
    }
}
// #endregion Films
// #region User

export function isUserLoaded(state: IStore): boolean {
    return !!state.user.login;
}

export function loadUser(state: IStore, to: RouteLocationNormalized): Promise<null> {
    return Promise.resolve(state.user.login ? null : store.dispatch('user/load'));
}
// #endregion User
// #region Public films
export function isPublicFilmsLoaded() {
    return store.hasModule('publicFilms');
}

export function loadPublicFilms(state: IStore, to: RouteLocationNormalized): Promise<null> {
    return registerPublicFilmsStore().then(() => {
        return store.dispatch('publicFilms/load', to.params.data);
    });
}

function registerPublicFilmsStore() {
    return import('@/store/filmsPublic').then((module) => {
        if (!store.hasModule('publicFilms')) {
            store.registerModule('publicFilms', module.default);
        }
    });
}
// #endregion Public films
